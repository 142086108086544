/* Estilos generales */
body {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  box-sizing: border-box;
}
.navbar {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.sgr,
.servicios,
.testimonios {
  min-height: 100vh;
}

.parrafoTamaño {
  font-size: 1.1rem;
}

/* navbar */

.logo-menu {
  width: 30%;
}
.nav-link {
  font-weight: 600 !important;
  color: #494949 !important;
}
.nav-link:hover {
  color: #000000 !important;
}
.navbar {
  opacity: 0.7;
  min-height: 13vh;
}

/* navbar cheques */
.logo-menu2{
  width: 12%;
}

/* portada sgr*/

.portada {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: url("./img/FondoPortada.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 140vh;
  margin-top: -7.3vh;
}
.sgrDivPortada {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 35rem;
  margin-left: 5rem;
}
.botonSaberMas {
  background-color: rgb(93, 192, 93);
  color: aliceblue;
  font-weight: 600;
  width: 8rem;
  border-radius: 20px;
  margin-left: 5rem;
}
.sgrPortada {
  font-weight: 700;
  color: aliceblue;
}

/*pagina que es una SGR */

.sgr {
  margin: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8rem;
}
.sgrImg {
  width: 18rem;
}
.sgrImg2 {
  display: none;
}
.tituloQueEsSGR {
  color: #494949;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
}
p {
  font-weight: 500;
}
.parradoSGRVerde {
  color: rgb(18, 131, 131);
}
.parradoSGRVerde2 {
  color: rgb(19, 83, 83);
}

/* pagina servicios */
.card {
  max-height: 80vh;
  border: none;
  justify-content: center;
  align-items: center;
}
.card {
  background-color: #bdbdbd;
}
.imgCard {
  margin-top: 2vh;
  width: 50%;
  max-width: 175px;
}
.iconEmail {
  font-size: 2.8rem;
}

/* Pagina testimonios */
.h5Testimonios {
  color: rgb(18, 131, 131);
  font-weight: 700;
}

.testiminio {
  background-color: #f4f5f7;
  padding: 25px;
  border-radius: 20px;
}
.parrafoDiv {
  width: 30vw;
}

/* valores */

.valoresAvalados {
  display: flex;
  flex-direction: row;
  align-items:flex-start;
  justify-content: center;
  margin-top: 5rem;
  gap: 6rem;
}
.valoresImg {
  width: 20rem;
}
.btnEpyme{
  font-weight: 600;
  color: rgb(18, 131, 131);
  padding: 0;
}

/* Portado Cheques */

.portadaCheques{
  background-image: url("./img/FondoPortada.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
}
.tituloCheques{
  text-align: center;
  color: aliceblue;
  margin-bottom: 3vh;
}
.formularioCheques{
  padding-top: 20vh;
}
.formInputs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.colorBtn{
  background-color: rgb(93, 192, 93);
  color: aliceblue;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
}
.atras {
  z-index: 999;
}
.noti {
  z-index: 5;
}
.fondoTostada{
  background-color: #000000;
  opacity: 0.9;
}
.resultadoApiCheque{
  max-width: 65vw;
  background-color: #eeeeee;
  padding: 2rem;
  border-radius: 30px;
}
.br{
  border-bottom: 1px solid #696868;
}
.arrow{
  font-size: 2rem;
  margin-top: 5rem;
}
.arrow:hover{
  color: #494949;
}
.colorVerde{
  color:rgb(18, 131, 131);
}

  /* Nosotros */

.cardPerfil{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nombrePerfil{
    color: rgb(18, 131, 131);
    font-size: 1rem;
}
.imgFotoPerfil{
  width: 75%;
}

/* contacto Footer */

.contacto {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  background-image: url(./img/FondoFooter.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.titulo {
  color: #494949;
  font-size: 1.1rem;
  font-weight: 700;
}
.tituloContacto{
  font-size: 1rem;
}
.datosContacto {
  color: aliceblue;
  font-size: 0.9rem;
}
.flexCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexCenterColunm{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.marginRight{
  margin-right: 5rem;
}
.LogoAFIP{
  width: 27%;
  margin-bottom: 1rem;
}
.Whatsapp{
  color: #52CE5F !important;
}
.WhatsappIcon{
  font-size: 2.5rem;
}
@media (max-width: 576px) {
  /* generales */

  .tituloQueEsSGR {
    font-size: 1.1rem;
  }

  /* NavBar */
  .logo-menu {
    width: 30% !important;
    margin: 1rem;
  }

  /* portada */

  .portada {
    background-size: contain;
    align-items: center;
    justify-content: start;
    height: 100vh;
  }
  .sgrPortada {
    font-size: 0.9rem;
    margin-top: 18vh;
  }
  .sgrDivPortada {
    max-width: 25rem;
    margin-left: 0rem;
    padding: 2rem;
    font-size: 1rem;
  }
  .botonSaberMas {
    width: 8rem;
    border-radius: 20px;
    margin-left: 0rem;
  }

  /* que es sgr */

  .sgr {
    flex-direction: column;
    margin-top: -8vh;
    gap: 0;
  }
  .sgrImg {
    display: none;
  }
  .sgrImg2 {
    width: 90vw;
  }
  .sgrInfo {
    width: 80vw;
    padding: 2rem;
    font-size: 1rem;
  }
  /* servicios  */
  .tituloServicios {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* valores */
  .valoresAvalados {
    flex-direction: column;
    align-items:center;
    gap: 0rem;
  }

  /* valores*/
  .imgFotoPerfil{
    width: 50%;
  }
  .pymeDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pymeDiv{
    width: 25rem;
  }
  .parrafoDiv {
    width: 30rem;
  }
  .valoresImg {
    width: 15rem;
  }
  .parrafoTamaño {
    font-size: 1rem;
  }
  .titulo {
    color:#494949;
    font-weight: 700;
  }

  /* Portado Cheques */

  .resultadoApiCheque{
    max-width: 92vw;
    padding-left: 13px;
    padding-right: 13px;
  }
  .resultadoApiCheque h5{
    font-size: 1.1rem;
  }
  .resultadoApiCheque p{
    font-size: 0.9rem;
  }

  /* Footer */
  .tituloContacto{
    color: aliceblue;
    font-weight: 800;
  }
  .datosContactoContMail{
    margin-bottom: 5px !important;
  }
  .datosContactoCell{
    margin-bottom: 10px !important;
  }
  .titulo{
    color: #494949 !important;
  }
  .marginRight{
    margin-right: 2rem;
  }
  .flexCenter {
    margin-right: 2rem;
    justify-content: flex-end;
    align-items: flex-end;
}

}
@media (max-width: 770px) {
  .logo-menu {
    width: 40%;
  }
  .titulo{
    font-size: 1.2rem;
    color: aliceblue;
  }
  .tituloContacto{
    font-size: 1rem;
  }
  .datosContacto{
    font-size: 0.8rem;
  }
}
@media (max-width: 1023px) {
  .sgr {
    flex-direction: column;
  }
  .sgrInfo {
    width: 30rem;
  }
  .sgrImg {
    display: none;
  }
  .sgrImg2 {
    display: inline-block;
    width: 80vw;
  }
  .valoresAvalados {
    gap: 3rem;
  }
  .pymeDiv{
    width: 15rem;
  }
}
@media (width: 1440px) and (min-width: 1921px){
  .sgrDivPortada {
    max-width: 25rem;
  }
}
@media (width: 768px){
  .sgrImg2{
    width: 50vw;
  }
}

.half-black {
  background: rgba(0, 0, 0, 0.8) !important;

}

.atras{
  z-index: 999;
  }

.noti{
  z-index: 5;
}
